<template>
  <div ref="scrollTarget" class="md:bg-white">
    <TabGroup :selected-index="selectedTab" @change="changeTab">
      <div
        class="border-b-[10px] border-t-[10px] lg:border-t-[13px] border-gray sticky-element"
      >
        <TabList class="flex overflow-x-auto no-scrollbar max-lg:px-[15px]">
          <Tab
            v-for="tab in tabs.filter(item => item)"
            :key="tab.label"
            v-slot="{ selected }"
            as="template"
          >
            <button
              :class="[
                selected
                  ? 'border-primary text-primary'
                  : 'border-transparent text-gray-mud hover:text-primary',
                'whitespace-nowrap border-b-2 py-4 lg:py-5 px-2.5 lg:px-[30px] text-[11px] lg:text-[12px] focus:outline-none'
              ]"
            >
              <span :id="tab.hash" class="uppercase font-bold h-full">
                {{ tab.label }}
              </span>
            </button>
          </Tab>
        </TabList>
      </div>

      <TabPanels>
        <!-- Description panel -->
        <TabPanel class="tab-panel lg:!pt-0">
          <slot name="product-info" />
          <LazyProductSectionsDetailsRichContent />
          <div
            class="grid lg:grid-cols-[1fr_auto] gap-y-5 lg:gap-y-10 mt-10 lg:gap-x-20"
          >
            <LazyProductSectionsDetailsDescription
              expandable
              class="lg:col-span-1 lg:col-start-1"
            />
            <LazyProductSectionsDetailsSpecifications
              expandable
              class="lg:col-span-1 lg:col-start-1"
            />
            <LazyProductSectionsDetailsVideo
              class="lg:col-span-1 lg:col-start-1"
            />
            <client-only>
              <LazyProductSectionsDetailsReviews
                class="lg:col-span-1 lg:col-start-1"
              />
              <LazyProductSectionsDetailsAddFeedbackExtended
                v-if="authStore.loggedIn"
                class="lg:col-span-1 lg:col-start-1"
              />
              <LazyProductSectionsDetailsModels
                class="lg:col-span-2 lg:col-start-1"
              />
              <LazyProductSectionsDetailsFAQ
                class="lg:col-span-1 lg:col-start-1"
              />
            </client-only>
            <LazyProductSectionsDetailsBuyBanner
              class="hidden lg:flex lg:col-span-1 lg:col-start-2 lg:row-span-4 lg:row-start-1"
            />
          </div>
        </TabPanel>

        <!-- Specifications panel -->
        <TabPanel class="tab-panel">
          <LazyProductSectionsDetailsSpecifications :expandable="false" />
        </TabPanel>

        <!-- Video panel -->
        <TabPanel v-if="isShowTab('videos')" class="tab-panel">
          <LazyProductSectionsDetailsVideo />
        </TabPanel>

        <!-- Model panel -->
        <TabPanel v-if="isShowTab('models')" class="tab-panel">
          <LazyProductSectionsDetailsModels />
        </TabPanel>

        <!-- Reviews panel -->
        <TabPanel v-if="isShowTab('reviews')" class="tab-panel">
          <LazyProductSectionsDetailsReviews />
        </TabPanel>

        <!-- Related panel -->
        <TabPanel v-if="isShowTab('related')" class="tab-panel">
          <LazyProductSectionsDetailsRelatedProductsLists
            :product="product"
            :variant="currentVariant"
          />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>
<script setup>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import { useStorage } from '@vueuse/core'
import { useAuthStore } from '~/stores/auth'

const { t } = useI18n()
const authStore = useAuthStore()

const emit = defineEmits(['scroll-to-top'])

const selectedTab = ref(0)
const scrollTarget = ref(null)

const product = inject('product')
const currentVariant = inject('currentVariant')

function changeTab(index) {
  if (index < 0) {
    return false
  }
  emit('scroll-to-top')
  selectedTab.value = index
}
const targetTab = useStorage('target-tab')

const videoCount = computed(() => currentVariant.value?.videos?.length)
const tabs = computed(() => [
  {
    label: t('product_page_info'),
    extra: null,
    hash: 'description'
  },
  {
    label: t('Характеристики'),
    extra: null,
    hash: 'specifications'
  },
  videoCount.value
    ? {
        label: `${t('Відео')} (${videoCount.value || 0})`,
        extra: null,
        hash: 'videos'
      }
    : false,
  product.value.variants.filter(v => v.code !== currentVariant.value.code)
    ?.length
    ? {
        label: `${t('Моделі')} (${
          product.value.variants.filter(
            v => v.code !== currentVariant.value.code
          )?.length || 0
        })`,
        extra: null,
        hash: 'models'
      }
    : false,
  product.value?.totalReviews
    ? {
        label: `${t('Відгуки')} (${product.value?.totalReviews || 0})`,
        extra: null,
        hash: 'reviews'
      }
    : false,
  currentVariant.value?.totalRelated
    ? {
        label: `${t('Супутні')} (${currentVariant.value?.totalRelated || 0})`,
        extra: null,
        hash: 'related'
      }
    : false
])

const isShowTab = tabHash => {
  return !!tabs.value?.find(item => item && item?.hash === tabHash)
}

const route = useRoute()

const getTabIndex = hash => {
  if (!hash) {
    hash = route.hash.replace('#', '')
  }
  return tabs.value
    .filter(i => i !== false)
    .findIndex(t => {
      return t.hash === hash
    })
}
const showTabDependsOnAnchor = () => {
  if (!route.hash) {
    return false
  }

  changeTab(getTabIndex())
}

// const selectTabByHash = hash => {
//   const index = getTabIndex(hash)
//   changeTab(index)
// }

watch(
  () => route.fullPath,
  () => {
    showTabDependsOnAnchor()
  },
  {
    deep: true,
    immediate: true
  }
)

watch(
  () => targetTab.value,
  v => {
    if (v && v !== 'undefined') {
      changeTab(getTabIndex(v))
      scrollTarget.value.scrollIntoView({ behavior: 'smooth' })
      nextTick(() => (targetTab.value = 'undefined'))
    }
  }
)

provide('getTabIndex', getTabIndex)
provide('selectedProductTab', selectedTab)
provide('productTabScrollTarget', scrollTarget)
</script>

<style scoped>
.sticky-element {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  align-self: flex-start;
  z-index: 100;
  background-color: #fff;
  @apply top-0 md:top-[110px];
}
.tab-panel {
  @apply p-[11px] px-[10px] md:px-[20px] pt-[23px] lg:p-[30px] lg:pt-[40px];
}
</style>
