<template>
  <ListHorizontal
    v-if="data"
    base-slide-width="380"
    :slides="data"
    slide-class="mb-5 p-4 bg-gray !h-auto"
    navigation-class="hidden md:block md:!top-[55%]"
    header-class="!min-h-0"
  >
    <template #default="{ slide: article }">
      <div class="flex gap-3 h-full">
        <div class="h-15 min-w-[60px] hidden lg:block">
          <nuxt-img
            :src="article?.image || NO_IMAGE_PATH"
            width="120"
            height="60"
            class="rounded"
            loading="lazy"
          />
        </div>
        <div class="flex flex-col gap-2">
          <div class="text-xs leading-5 line-clamp-2">
            {{ article?.taxons[0]?.name }}
          </div>
          <div class="text-base font-semibold line-clamp-2">
            {{ article?.name }}
          </div>
          <NuxtLink
            :to="'/post/' + article.slug"
            class="link-underline text-sm mt-auto w-fit"
          >
            {{ t('Детальніше') }}
          </NuxtLink>
        </div>
      </div>
    </template>
  </ListHorizontal>
</template>

<script setup>
const { t } = useI18n()
const { code } = inject('currentVariant').value

const { data } = useLazyAsyncData(
  `${code}-digest`,
  () => useApi().news.getForProduct(code),
  { transform: res => res['hydra:member'] }
)
</script>
