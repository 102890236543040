<template>
  <div>
    <div
      v-if="product?.isSlider && product?.variants?.length > 1"
      class="mt-5 h-[147px]"
    >
      <ProductOptionsOptionProductSlider />
    </div>
    <div v-else>
      <template v-for="(option, idx) in allOptions" :key="option.id">
        <ProductOptionsOptionPickerProduct
          :value="selectedValues[option?.code]"
          :options="option?.values"
          :type="option?.type"
          :option-index="idx + 1"
          :selected="selectedValues"
          :label="props.showLabel ? option?.name : ''"
          :options-array-count="allOptions?.length"
          :parent-codes="idx !== 0 ? getParentCodes(idx) : []"
          class="mt-[18px]"
          @select="onSelect"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
import fromPairs from 'lodash/fromPairs'
import uniqBy from 'lodash/uniqBy'
// import sortBy from 'lodash/sortBy'
// import last from 'lodash/last'

const product = inject('product')
const currentVariant = inject('currentVariant')

const props = defineProps({
  showLabel: {
    type: Boolean,
    default: true
  },
  size: {
    type: String,
    default: 'md'
  },
  productLink: {
    type: String,
    default: () => ''
  }
})

const allOptions = ref([])

const selectedValues = computed(() =>
  fromPairs(
    currentVariant.value?.optionValues?.map(v => [v?.optionCode, v?.code])
  )
)
const setAllOptions = () => {
  allOptions.value = product.value
    ?.getSelectOptionsProduct()
    ?.filter(
      options =>
        options?.values?.length > 1 &&
        uniqBy(options?.values, v => v?.code)?.length > 1
    )
}

const getParentCodes = idx => {
  const codes = []
  for (let i = 0; i < idx; i++) {
    codes.push(selectedValues.value[allOptions.value[i]?.code])
  }

  return codes
}

const onSelect = data => {
  navigateTo(
    useLocalePath()(`/${useRoute().params?.category}/details/${data?.slug}/`)
  )
}

setAllOptions()
</script>
