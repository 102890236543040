<template>
  <AppFormSelect
    :key="key"
    :model-value="selectedOption"
    :options="sortedOptions"
    :get-label="o => o?.displayValue || o?.value"
    :get-value="o => o?.code"
    button-class="min-w-[100px] border border-gray"
    option-container-class="min-w-[100px]"
    :input-class="'h-11 md:rounded-r-none'"
    @update:model-value="v => emits('select', v)"
  />
</template>

<script setup>
const mouseOver = inject('mouseOver', ref(false))
const props = defineProps({
  options: {
    type: Array,
    default: () => []
  },
  value: {
    type: [Object, String],
    default: () => {}
  },
  label: {
    type: String,
    default: null
  },
  type: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: 'md'
  }
})
const emits = defineEmits(['select'])
const { slug } = useRoute()?.params

const key = ref(null)
/* сортування картка default */
const sortedOptions = computed(() => {
  return addSuffixToDuplicates(sortOptionsVariants(props.options))
  // return sortOptionsVariants(props.options)
})

const addSuffixToDuplicates = arr => {
  const countMap = {}

  arr.map(item => {
    if (countMap[item.value]) {
      countMap[item.value]++
      item.displayValue = `${item.value || '-'} #${countMap[item.value]}`
      return item
    } else {
      countMap[item.value] = 1
      item.displayValue = item.value || '-'
      return item
    }
  })
  return arr
}

const selectedOption = computed(() => {
  const foundOption = sortedOptions.value?.find(o => o?.slug === slug)

  return foundOption || sortedOptions.value?.find(o => o?.code === props.value)
})

watch(mouseOver, () => {
  if (!mouseOver.value) {
    key.value = Date.now().toString()
  }
})
</script>
