<template>
  <div v-if="optionValues?.length > 1">
    <div v-if="props.label" class="text-gray-mud text-sm">
      {{ props.label }}
    </div>
    <template v-if="props.type === 'default'">
      <ProductOptionsOptionDefaultProduct
        v-if="props?.options"
        :value="props?.value"
        :options="optionValues"
        :type="props?.type"
        :label="props?.label"
        class="mt-[18px]"
        @select="onSelect"
      />
    </template>
    <template v-else>
      <div class="flex flex-wrap gap-3 mt-3">
        <ProductOptionsOptionPlatesProduct
          :value="props.value"
          :options="optionValues"
          :type="props.type"
          :label="props.label"
          class="mt-[18px]"
          :is-selected-by-code="isSelectedByCode"
          :is-parent-code="!props.parentCodes?.length"
          :is-last-options
          @select="onSelect"
        />
      </div>
    </template>
  </div>
</template>
<script setup>
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'

const props = defineProps({
  options: {
    type: Array,
    default: () => []
  },
  value: {
    type: [Object, String],
    default: () => {}
  },
  label: {
    type: String,
    default: null
  },
  type: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: 'md'
  },
  parentCodes: {
    type: Array,
    default: () => []
  },
  optionsArrayCount: {
    type: Number,
    default: 0
  },
  optionIndex: {
    type: Number,
    default: 0
  },
  selected: {
    type: Object,
    default: () => {}
  }
})
const emits = defineEmits(['select'])

const isSelectedByCode = ref(true)

const optionValues = computed(() => {
  if (props.parentCodes?.length) {
    if (props.optionsArrayCount > props.optionIndex) {
      isSelectedByCode.value = true
      return uniqBy(
        sortBy(
          props.options?.filter(item => {
            return (
              item &&
              props.parentCodes?.every(option =>
                item?.allOptions?.includes(option)
              )
            )
          }),
          ['available']
        )?.reverse(),
        v => v?.code
      )
    }
    isSelectedByCode.value = false
    return props.options?.filter(item => {
      return (
        item &&
        props.parentCodes?.every(option => item?.allOptions?.includes(option))
      )
    })
  }

  if (props.optionsArrayCount > props.optionIndex) {
    isSelectedByCode.value = true
    return uniqBy(
      sortBy(props.options, ['available'])?.reverse(),
      v => v?.code
    )?.filter(v => !!v)
  }
  isSelectedByCode.value = false
  return props.options?.filter(v => !!v)
})

const onSelect = payload => emits('select', payload)
</script>
