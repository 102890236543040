<template>
  <svg width="160" height="60" viewBox="0 0 115 50">
    <rect class="st0" width="126.5" height="45.35" />
    <g>
      <g>
        <polygon
          points="117.17,38.68 105.85,38.68 105.85,37.91 116.4,37.91 116.4,27.36 117.17,27.36 "
        />
        <polygon
          points="10.09,17.45 9.33,17.45 9.33,6.14 20.65,6.14 20.65,6.9 10.09,6.9 "
        />
        <polygon
          points="117.17,17.45 116.4,17.45 116.4,6.9 105.85,6.9 105.85,6.14 117.17,6.14 "
        />
        <polygon
          points="20.65,38.68 9.33,38.68 9.33,27.36 10.09,27.36 10.09,37.91 20.65,37.91 "
        />
      </g>
      <g id="logo">
        <path
          d="M79.58,9.47v24.86l8.9,8.89l8.9-8.89V9.47H79.58z M97.07,34.2l-8.6,8.58l-8.6-8.58V9.79h17.19V34.2z"
        />
        <path
          d="M96.01,33.72V20.94l-9.46,9.63v5.13l8.84-8.98v6.75l-6.92,6.91l-6.91-6.9l14.45-14.59v-8.1H80.93v16.13l9.44-9.59v-5.13l-8.83,8.97v-9.77h13.85v2.99L80.93,28.99v4.71l7.55,7.54L96.01,33.72z"
        />
      </g>
      <g>
        <g>
          <path d="M27.62,14.55h-1.3v-1.3h4.04v1.3H29v6.16h-1.38V14.55z" />
          <path
            d="M35.21,17.89v1.43c0,0.87-0.49,1.39-1.3,1.39h-2.88v-7.45h2.84c0.83,0,1.32,0.48,1.32,1.31v1.38c0,0.46-0.21,0.83-0.59,1C35.06,17.1,35.21,17.45,35.21,17.89z M32.43,16.26h1.06c0.24,0,0.36-0.19,0.36-0.45v-0.93c0-0.2-0.1-0.33-0.37-0.33h-1.05V16.26z M33.87,17.96c0-0.26-0.13-0.38-0.4-0.38h-1.03v1.82h1.07c0.26,0,0.36-0.13,0.36-0.36L33.87,17.96z"
          />
          <path
            d="M35.92,19.4v-4.8c0-0.87,0.46-1.34,1.37-1.34h1.45c0.88,0,1.35,0.49,1.35,1.34v4.8c0,0.77-0.49,1.31-1.34,1.31h-1.49C36.41,20.7,35.92,20.22,35.92,19.4z M38.43,19.4c0.19,0,0.29-0.13,0.29-0.4v-4.03c0-0.31-0.12-0.43-0.33-0.43h-0.75c-0.24,0-0.34,0.12-0.34,0.43v4.03c0,0.27,0.11,0.4,0.34,0.4H38.43z"
          />
          <path
            d="M45,13.25v7.45h-1.38v-2.84h-0.67L42.2,20.7h-1.37l0.77-2.91c-0.51-0.17-0.77-0.59-0.77-1.3v-1.99c0-0.86,0.52-1.26,1.43-1.26H45z M43.63,14.55H42.6c-0.29,0-0.42,0.12-0.42,0.39v1.22c0,0.27,0.13,0.4,0.42,0.4h1.02V14.55z"
          />
          <path d="M52.11,13.25v1.31h-2.33v6.15H48.4v-7.45H52.11z" />
          <path
            d="M52.8,13.25h2.86c0.83,0,1.31,0.5,1.31,1.39v2.07c0,0.95-0.45,1.44-1.39,1.44h-1.4v2.56H52.8V13.25z M55.24,16.8c0.27,0,0.38-0.12,0.38-0.37v-1.52c0-0.24-0.15-0.37-0.38-0.37h-1.06v2.26H55.24z"
          />
          <path
            d="M62.08,13.25v7.45h-1.36v-3.88l-1.74,3.88h-1.25v-7.45h1.35l-0.04,3.93l1.68-3.93H62.08z"
          />
          <path
            d="M67.09,17.89v1.43c0,0.87-0.49,1.39-1.3,1.39h-2.88v-7.45h2.84c0.83,0,1.32,0.48,1.32,1.31v1.38c0,0.46-0.21,0.83-0.59,1C66.93,17.1,67.09,17.45,67.09,17.89z M64.3,16.26h1.06c0.24,0,0.36-0.19,0.36-0.45v-0.93c0-0.2-0.1-0.33-0.37-0.33H64.3V16.26zM65.74,17.96c0-0.26-0.13-0.38-0.4-0.38H64.3v1.82h1.07c0.26,0,0.36-0.13,0.36-0.36L65.74,17.96z"
          />
          <path
            d="M67.86,13.25h1.38v3h1.34v-3h1.37v7.45h-1.37v-3.14h-1.34v3.14h-1.38V13.25z"
          />
          <path
            d="M76.91,13.25v7.45h-1.38v-2.84h-0.67l-0.76,2.84h-1.37l0.77-2.91c-0.51-0.17-0.77-0.59-0.77-1.3v-1.99c0-0.86,0.52-1.26,1.43-1.26H76.91z M75.54,14.55h-1.02c-0.29,0-0.42,0.12-0.42,0.39v1.22c0,0.27,0.13,0.4,0.42,0.4h1.02V14.55z"
          />
          <path
            d="M38.05,23.94h1.08V25h-1.08V23.94z M38.66,25.4h1.38v7.45h-1.38V25.4z M39.56,23.94h1.09V25h-1.09V23.94z"
          />
          <path
            d="M43.86,29.1l1.6,3.74h-1.54l-0.86-2.13l-0.87,2.13h-1.51l1.57-3.73l-1.57-3.72h1.52l0.87,2.14l0.84-2.14h1.54L43.86,29.1z"
          />
          <path
            d="M46.09,25.4h1.38v3h1.34v-3h1.37v7.45h-1.37v-3.14h-1.34v3.14h-1.38V25.4z"
          />
          <path
            d="M55.14,25.4v7.45h-1.38v-2.84H53.1l-0.76,2.84h-1.37l0.77-2.91c-0.51-0.17-0.77-0.59-0.77-1.3v-1.99c0-0.86,0.52-1.26,1.43-1.26H55.14z M53.76,26.69h-1.02c-0.29,0-0.42,0.12-0.42,0.39v1.22c0,0.27,0.13,0.4,0.42,0.4h1.02V26.69z"
          />
          <path
            d="M64.14,25.4l-0.01,7.45h-1.31v-4.11l-1.06,2.57h-0.8l-1.08-2.62v4.16h-1.34V25.4h1.45l1.37,3.32l1.37-3.32H64.14z"
          />
          <path d="M65.01,25.4h1.38v7.45h-1.38V25.4z" />
          <path
            d="M72.12,31.47v2.19h-1.15v-0.81h-3.73V25.4h1.38v6.07h1.53V25.4h1.38v6.07H72.12z"
          />
          <path
            d="M72.8,25.4h1.38v2.56h1.4c0.94,0,1.39,0.49,1.39,1.44v2.07c0,0.88-0.48,1.39-1.31,1.39H72.8V25.4z M75.24,31.54c0.23,0,0.38-0.13,0.38-0.36v-1.53c0-0.25-0.11-0.37-0.38-0.37h-1.06v2.26H75.24z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
.st0 {
  fill: #f3911f;
}
</style>
