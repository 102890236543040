<template>
  <div class="flex flex-col lg:flex-row lg:gap-[20px]">
    <div class="relative flex lg:block">
      <div class="relative min-w-[80px] h-[80px] lg:w-[130px] lg:h-[130px]">
        <nuxt-img
          width="130"
          height="130"
          :src="slide.author?.user?.avatar?.path || NO_IMAGE_PATH"
          class="rounded-full object-cover w-[80px] h-[80px] lg:w-[130px] lg:h-[130px]"
          loading="lazy"
        />
        <div
          class="flex mb-2 max-w-fit items-center p-2 rounded absolute bottom-[-25px] left-[40px] lg:left-1/2 -translate-x-1/2"
          :style="{ 'background-color': '#8bcbfd' }"
        >
          <IconsTagsExpert />
        </div>
      </div>
      <div class="block lg:hidden ml-5">
        <div class="font-semibold">
          {{ slide.author.firstName }}
        </div>
        <div class="mt-1 text-sm">
          <div v-if="slide.author?.shortDescription" class="flex items-start">
            <IconsSocialsYoutubeGray
              color="#FF0000"
              class="mt-0.5 w-4 h-4 mr-2 items-center text-wrap"
            />
            <div>{{ slide.author?.shortDescription }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col flex-1 text-xs">
      <div class="gap-2.5 hidden lg:flex items-center">
        <span class="font-semibold">{{ slide.author.firstName }}</span>
        <span v-if="slide.author?.shortDescription" class="ml-4 flex">
          <IconsSocialsYoutubeGray
            color="#FF0000"
            class="w-4 h-4 mr-2 items-center text-wrap"
          />
          {{ slide.author?.shortDescription }}
        </span>
        <span class="ml-auto">{{ slide?.getDate() }}</span>
      </div>
      <app-expandable-section
        :button-text="$t('Детальніше')"
        :button-text-expanded="$t('Приховати')"
        button-wrapper-class="flex justify-start"
        button-class="underline-offset-[3px]"
        :show-chevron="false"
        expandable
        gradient="to-[#D0E9FA]"
        :collapsed-hight="85"
      >
        <div class="flex flex-col gap-2.5 mt-3 lg:mt-0">
          <div v-html="slide?.textWithLink(slide?.comment)"></div>
          <div v-if="slide?.pros" class="flex">
            <div class="font-semibold mr-2 text-green-ibis">
              {{ $t('Переваги') }}:
            </div>
            <div v-html="slide?.textWithLink(slide?.pros)"></div>
          </div>
          <div v-if="slide?.cons" class="flex">
            <div class="font-semibold mr-2 text-orange">
              {{ $t('Недоліки') }}:
            </div>
            &nbsp;
            <div v-html="slide?.textWithLink(slide?.cons)"></div>
          </div>
        </div>
      </app-expandable-section>
      <div class="flex flex-col gap-2.5 w-auto">
        <div
          v-if="slide?.youtube || slide?.hasMedia()"
          class="mask-gradient flex justify-start gap-1.5 mt-3 mb-2 flex-wrap relative h-[60px]"
        >
          <div class="absolute h-[60px] bottom-0 left-0 flex gap-x-2">
            <ListExpertsReviewMedia :slide="slide" class="z-1" />
          </div>
        </div>
        <div
          class="flex justify-between items-center lg:justify-start"
          :class="!slide?.youtube && !slide?.hasMedia() && 'mt-4'"
        >
          <div class="flex gap-2.5">
            <a
              type="button"
              class="flex gap-1 items-center cursor-pointer"
              :class="
                slide?.reacted === 'like' ? 'text-green-ibis' : 'text-gray-mud'
              "
              @click="emit('toggleReaction', 'like', slide?.id)"
            >
              <IconsReactionLike class="h-3.5 w-3.5" />
              <span>{{ slide?.totalReactions?.like }}</span>
            </a>
            <a
              type="button"
              class="flex gap-1 items-center cursor-pointer"
              :class="
                slide?.reacted === 'dislike' ? 'text-red-hot' : 'text-gray-mud'
              "
              @click="emit('toggleReaction', 'dislike', slide?.id)"
            >
              <IconsReactionDislike class="h-3.5 w-3.5" />
              <span> {{ slide?.totalReactions?.dislike }} </span>
            </a>
          </div>
          <span class="ml-auto block lg:hidden">{{ slide?.getDate() }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  slide: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(['toggleReaction'])
</script>

<style scoped>
.mask-gradient {
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0)
  );
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
}
</style>
