<template>
  <div>
    <ProductListTags
      :tags="currentVariant?.productTags"
      class="mt-8"
      @click.prevent.stop
    />
    <ProductListSABTag v-if="tempSABAdditionalTags" @click.prevent.stop />
    <ProductSectionsCommonPreviewSlider
      class="z-0"
      :allow-zoom="false"
      :images="media"
      :image-thumbs="media"
      :show-larger-button="true"
      @on-zoom="handleZoom"
    />
    <ProductSectionsCommonPreviewDialog
      :open="showModal"
      @close="showModal = false"
    >
      <ProductSectionsCommonPreviewSlider
        :allow-zoom="true"
        :initial-slide="zoomInitialSlider"
        :images="media"
        :image-thumbs="media"
        :show-larger-button="false"
        :show-thumbs="false"
        is-modal-slider
        size="lg"
      />
    </ProductSectionsCommonPreviewDialog>
  </div>
</template>

<script setup>
const currentVariant = inject('currentVariant')

const showModal = ref(false)
const zoomInitialSlider = ref(0)

const { data: images } = useLazyAsyncData(
  currentVariant.value?.code + '-original-images',
  () => useAsyncResources(currentVariant.value.images)
)

const media = computed(() => {
  return images.value && [...images.value, ...currentVariant.value?.videos]
})

const handleZoom = slideIndex => {
  zoomInitialSlider.value = slideIndex
  showModal.value = true
}

const tempSABcode = [
  '1030173',
  '1030060',
  '1030134',
  '1030171',
  '1030375',
  '1030124',
  '1030135',
  '1030115',
  '1030150',
  '1030156',
  '1033006',
  '1030111',
  '1030145',
  '1030155',
  '1030174',
  '1030921',
  '1032121'
]

const tempSABAdditionalTags = computed(() => {
  return tempSABcode.includes(currentVariant.value.code)
    ? currentVariant.value.code
    : false
})
</script>
