<template>
  <div class="parent-container relative">
    <div class="absolute z-1 right-0">
      <div class="flex mb-2 max-w-fit items-center p-8 rounded">
        <component :is="tagsTypes['product_tag_SAB']?.icon" />
      </div>
    </div>
  </div>
</template>

<script setup>
const { t } = useI18n()
const props = defineProps({
  tagses: {
    type: Array,
    default: () => []
  }
})

const tagsTypes = {
  product_tag_SAB: {
    name: t('Твоя 1 гривня'),
    icon: resolveComponent('IconsTagsSAB')
  }
}
</script>

<style scoped>
.parent-container {
  position: relative;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-15px);
  opacity: 0;
}
</style>
