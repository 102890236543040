<template>
  <ListHorizontal
    v-if="experts.length"
    id="expert-reviews"
    base-slide-width="560"
    :title="t(props.title)"
    :sub-title="t(props.subTitle)"
    class="mt-10 relative expert-reviews-slider scroll-mt-[90px] md:scroll-mt-[210px]"
    :title-class="props.titleClass"
    :space-between="30"
    :slides="experts"
    slide-class="mb-5 p-[10px] sm:p-[20px] bg-[#D0E9FA]"
    :slides-per-view="slidesPerView"
    :is-position-relative="false"
    :breakpoints="sliderBreakpoints"
  >
    <template #default="{ slide }">
      <ListExpertReviewItem :slide="slide" @toggle-reaction="toggleReaction" />
    </template>
  </ListHorizontal>
</template>

<script setup>
import ProductFeedbackEntity from '~/entities/ProductFeedback.entity'
import { useAuthStore } from '~/stores/auth'

const { t } = useI18n()
const props = defineProps({
  title: {
    type: String,
    default: 'Думка експертів'
  },
  subTitle: {
    type: String,
    default: ''
  },
  titleClass: {
    type: String,
    default: 'text-xl font-bold'
  },
  slidesPerView: {
    type: Number,
    default: 1
  },
  sliderBreakpoints: {
    type: Object,
    default: () => ({})
  }
})

const product = inject('product')
const authStore = useAuthStore()

const { data: expertsReviews } = await useAsyncData(
  'product-experts-reviews-' + (product?.value?.code || 'all'),
  () => {
    const filter = {
      page: 1,
      itemsPerPage: 15,
      customer: 'expert',
      'order[createdAt]': 'desc'
    }
    if (product?.value) {
      filter.reviewSubject = product.value['@id']
    }
    return useApi().productReviews.getAll(filter)
  },
  {
    transform: res => res['hydra:member']
  }
)

const toggleReaction = async (reaction, id) => {
  if (!authStore.loggedIn) {
    return authStore.showAuthDialog(useRoute().fullPath)
  }

  const index = experts.value?.findIndex(review => review.id === id)

  if (index !== -1) {
    const review = experts?.value[index]
    const currentReaction = review.reacted
    if (currentReaction) {
      review.totalReactions[currentReaction]--
    }
    review.reacted = currentReaction === reaction ? '' : reaction
    if (review.reacted) {
      review.totalReactions[review.reacted]++
    }
  }

  await useApi().productReviewLike.toggleReaction({
    id,
    type: reaction
  })
}

const experts = computed(
  () => expertsReviews.value?.map(item => new ProductFeedbackEntity(item)) || []
)
</script>

<style>
.expert-reviews-slider .navigation-button-next {
  @apply absolute top-[-10px] md:top-[-15px] right-[10px] md:right-[20px] md:w-[43px] md:h-[43px];
}
.expert-reviews-slider .navigation-button-prev {
  @apply absolute top-[-10px] md:top-[-15px] block md:w-[43px] md:h-[43px] left-[calc(100%-60px)] md:left-[calc(100%-65px)];
}
.expert-reviews-slider .swiper-button-disabled,
.expert-reviews-slider .swiper-button-disabled > * {
  @apply !block !text-gray-mud !cursor-not-allowed;
}
</style>
